/* eslint-disable no-case-declarations */
const {
  CREATE_QUESTION_START,
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_FROM_AI_COMPLETE,
  CREATE_QUESTION_ERROR,
  FETCH_START,
  FETCH_ERROR,
  FETCH_COMPLETE,
  FETCH_END,
  FETCH_ON_SET_BOOKMARK_COMPLETE,
  FETCH_PRODUCT_VARIATIONS_DETAILS,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  FETCH_SHIPPING_PROMISE_START,
  FETCH_SHIPPING_PROMISE_COMPLETE,
  QUANTITY_SHOW_INPUT_CHANGE,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  SET_PICKERS_COLLAPSED,
  SET_RECOMMENDATIONS_CONTEXT,
  FETCH_DEFERRED_START,
  FETCH_DEFERRED_SUCCESS,
  FETCH_DEFERRED_ERROR,
  ADD_TO_CART_UPDATE_START,
  ADD_TO_CART_UPDATE_COMPLETE,
  ADD_TO_CART_UPDATE_ERROR,
  SHOW_QUANTITY_ERROR,
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  TOGGLE_BOOKMARKED,
  FETCH_ON_COUPON_UPDATE_START,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
  ON_COUPON_BUYBOX_OFFERS_UPDATE,
  FETCH_ON_MELIPLUS_UPDATE_START,
  FETCH_ON_MELIPLUS_UPDATE_COMPLETE,
  FETCH_ON_CHANGE_TRADE_IN_START,
  FETCH_ON_CHANGE_TRADE_IN_COMPLETE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  BOTTOMSHEET_MODAL_UPDATE,
  BOTTOMSHEET_MODAL,
  BOTTOMSHEET_MODAL_CLOSE,
  FETCH_BOTTOMSHEET_MODAL,
  FETCH_ONE_PAY_FOR_ALL,
  ONE_PAY_FOR_ALL_SHOW_MODAL,
  ONE_PAY_FOR_ALL_IFRAME_LOADED,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  UPDATE_GIFT_REGISTRY,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
  TOGGLE_FOLLOW_OPTIMISTICALLY,
  FETCH_ON_SET_FOLLOW_COMPLETE,
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_QUESTION_AI_ERROR,
} = require('../actions/actions');

const {
  SEARCH_QADB_INIT,
  SEARCH_QADB_SUCCESS,
  SEARCH_QADB_ERROR,
  SEARCH_QADB_ON_BLUR,
  SEARCH_QADB_ON_FOCUS,
  SEARCH_QADB_CLOSE,
  SEARCH_QADB_CLOSE_COMPLETE,
  SEARCH_QADB_CALL_TO_ACTION_FOCUS,
  CLOSE_HOW_TO_MAKE_QUESTION_TOOLTIP,
  LEAVE_FEEDBACK_COMPLETE,
  MAKE_QUESTION_SUCCESS,
  MAKE_QUESTION_ERROR,
  MAKE_QUESTION,
  SET_SMART_ANSWER,
  CLOSE_SMART_ANSWER_MODAL,
  OPEN_SMART_ANSWER_MODAL,
  OPEN_QUESTION_FROM_SA_MODAL,
} = require('../components/qadb/actions/actions');
const {
  QUICK_ACTIONS,
  SEARCH_BAR,
  RESULTS,
  CARD_ANSWER,
  MAKE_QUESTION_SA,
  SMART_ANSWER_MODAL,
} = require('../components/qadb/qadb.constants');

const { messagesErrorScreen } = require('../lib/get-message-error');
const findActionSelected = require('./helpers/main-actions');

const updateArrayComponents = require('./helpers/update-array-components');

// eslint-disable-next-line complexity
const rootReducer = (state, action) => {
  const variationsComponent = state.components.variations?.state === 'VISIBLE' ? 'variations' : 'outside_variations';
  const { title, description } = messagesErrorScreen(state.siteId);
  const errorText = `${title} - ${description}`;

  switch (action.type) {
    case FETCH_END:
      return {
        ...state,
        isFetching: false,
      };

    case CREATE_QUESTION_START:
      return {
        ...state,
        components: {
          ...state.components,
          questions: {
            ...state.components.questions,
            questions_ai: {
              ...state.components.questions.questions_ai,
              isFetching: false,
            },
            messages: null,
            disabled: true,
            isFetching: true,
          },
        },
      };

    case CREATE_QUESTION_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          questions: {
            ...state.components.questions,
            disabled: false,
            messages: {
              ...action.payload.display_message,
              type: action.payload.success === true ? 'success' : 'error',
            },
            isFetching: false,
            stockModal: action.payload.stock_modal,
            stockTrack: action.payload.stock_track,
          },
        },
      };

    case CREATE_QUESTION_FROM_AI_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          questions: {
            ...state.components.questions,
            questions_ai: {
              ...state.components.questions.questions_ai,
              isFetching: false,
            },
            disabled: false,
            isFetching: false,
            stockModal: action.payload.stock_modal,
            stockTrack: action.payload.stock_track,
          },
        },
      };

    case CREATE_QUESTION_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          questions: {
            ...state.components.questions,
            questions_ai: {
              ...state.components.questions.questions_ai,
              isFetching: false,
            },
            messages: {
              text: errorText,
              type: 'error',
            },
            isFetching: false,
            disabled: false,
          },
        },
      };

    case FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case FETCH_START:
      return {
        ...state,
        components: {
          ...state.components,
          html_description: undefined,
        },
        isFetching: true,
        error: null,
      };

    case FETCH_ON_COUPON_UPDATE_START:
      return {
        ...state,
        components: {
          ...state.components,
          coupon_summary: {
            ...state.components.coupon_summary,
            isFetching: true,
          },
        },
      };

    case FETCH_ON_COUPON_UPDATE_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          coupon_summary: {
            ...action.payload.components.coupon_summary,
            isFetching: false,
          },
        },
      };

    case FETCH_ON_COUPON_UPDATE_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          coupon_summary: {
            ...state.components.coupon_summary,
            isFetching: false,
          },
        },
      };

    case ON_COUPON_BUYBOX_OFFERS_UPDATE:
      return {
        ...state,
        components: {
          ...state.components,
          buy_box_offers: action.payload.buy_box_offers,
        },
      };

    case FETCH_PRODUCT_VARIATIONS_DETAILS:
      return {
        ...state,
        components: {
          ...state.components,
          ...action.payload.components,
        },
        isFetching: false,
      };

    case FETCH_COMPLETE:
      return {
        ...state,
        id: action.payload.id,
        pathName: action.payload.pathName,
        parentUrl: action.payload.parentUrl,
        selected_offer_type: action.payload.selected_offer_type,
        components: {
          ...state.components,
          ...action.payload.components,

          [variationsComponent]: action.payload.components?.[variationsComponent]
            ? {
                ...state.components[variationsComponent],
                ...action.payload.components[variationsComponent],
                pickers: action.payload.components?.[variationsComponent]?.pickers
                  ? action.payload.components[variationsComponent].pickers.map(picker => {
                      if (picker.display_info) {
                        const oldDisplayInfo = state.components[variationsComponent].pickers.filter(
                          oldPicker => oldPicker.id === picker.id,
                        )[0].display_info;
                        if (oldDisplayInfo) {
                          picker.display_info.is_collapsed = oldDisplayInfo.is_collapsed;
                        }
                      }
                      return picker;
                    })
                  : null,
              }
            : {
                ...(state.components[variationsComponent]
                  ? { ...state.components[variationsComponent] }
                  : { state: 'HIDDEN' }),
              },
          html_description: {
            ...action.payload.components.html_description,
            fetched: false,
          },
        },
        recommendations_context: { ...action.payload.recommendations_context },
        isFetching: false,
        dynamic_scroll: action.payload.dynamic_scroll,
        preload: action.payload.preload,
        error: null,
      };

    case FETCH_ON_SET_QUANTITY_START:
    case FETCH_SHIPPING_PROMISE_START:
      return {
        ...state,
        components: {
          ...state.components,
          available_quantity: {
            ...state.components.available_quantity,
            isFetching: true,
            quantity_selector: {
              ...state.components.available_quantity?.quantity_selector,
            },
            selected: action.payload.quantity,
          },
          shipping_summary: {
            ...state.components.shipping_summary,
            isFetching: true,
          },
          second_shipping_summary: {
            ...state.components.second_shipping_summary,
            isFetching: true,
          },
          meliplus_summary: {
            ...(state.components.meliplus_summary || { state: 'hidden' }),
            isFetching: true,
          },
          upselling_meliplus_summary: {
            ...(state.components.upselling_meliplus_summary || { state: 'hidden' }),
            isFetching: true,
          },
          pick_up_summary: {
            ...state.components.pick_up_summary,
            isFetching: true,
          },
          returns_summary: {
            ...state.components.returns_summary,
            isFetching: true,
          },
        },
        buttonFocus: false,
      };

    case FETCH_ON_SET_QUANTITY_COMPLETE:
    case FETCH_SHIPPING_PROMISE_COMPLETE:
      return {
        ...state,
        parentUrl: action.payload.parentUrl,
        selected_offer_type: action.payload.selected_offer_type,
        components: {
          ...state.components,
          ...action.payload.components,
          payment_summary: {
            styles: state.components.payment_summary.styles,
            ...action.payload.components.payment_summary,
          },
        },
        buttonFocus: true,
        preload: action.payload.preload,
      };

    case FETCH_ON_MELIPLUS_UPDATE_START:
      return {
        ...state,
        components: {
          ...state.components,
          shipping_summary: {
            ...state.components.shipping_summary,
            isFetching: true,
          },
          second_shipping_summary: {
            ...state.components.second_shipping_summary,
            isFetching: true,
          },
          meliplus_summary: {
            ...state.components.meliplus_summary,
            isFetching: true,
          },
          upselling_meliplus_summary: {
            ...state.components.upselling_meliplus_summary,
            isFetching: true,
          },
          pick_up_summary: {
            ...state.components.pick_up_summary,
            isFetching: true,
          },
          returns_summary: {
            ...state.components.returns_summary,
            isFetching: true,
          },
        },
      };

    case FETCH_ON_MELIPLUS_UPDATE_COMPLETE:
      const mergedComponents = {
        ...state.components,
        ...action.payload.components,
      };
      return {
        ...state,
        components: {
          ...mergedComponents,
          shipping_summary: mergedComponents.shipping_summary && {
            ...mergedComponents.shipping_summary,
            isFetching: false,
          },
          second_shipping_summary: mergedComponents.second_shipping_summary && {
            ...mergedComponents.second_shipping_summary,
            isFetching: false,
          },
          meliplus_summary: mergedComponents.meliplus_summary && {
            ...mergedComponents.meliplus_summary,
            isFetching: false,
          },
          upselling_meliplus_summary: mergedComponents.upselling_meliplus_summary && {
            ...mergedComponents.upselling_meliplus_summary,
            isFetching: false,
          },
          pick_up_summary: mergedComponents.upselling_meliplus_summary && {
            ...mergedComponents.pick_up_summary,
            isFetching: false,
          },
          returns_summary: mergedComponents.upselling_meliplus_summary && {
            ...mergedComponents.returns_summary,
            isFetching: false,
          },
          fixed: mergedComponents.upselling_meliplus_summary && {
            ...state.components.fixed,
            ...action.payload.components.fixed,
          },
          ...updateArrayComponents(state.components, action.payload.components),
        },
        preload: action.payload.preload,
      };

    case QUANTITY_SHOW_INPUT_CHANGE:
      return {
        ...state,
        components: {
          ...state.components,
          available_quantity: {
            ...state.components.available_quantity,
            showInput: action.payload.show,
          },
        },
      };

    case TOGGLE_BOOKMARK_OPTIMISTICALLY:
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked: !state.components.bookmark.is_bookmarked,
            isFetching: true,
          },
        },
      };

    case FETCH_ON_SET_BOOKMARK_COMPLETE:
      /* @TODO fix this, should return bookmark structure */
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked:
              action.payload.status === 'ok'
                ? state.components.bookmark.is_bookmarked
                : !state.components.bookmark.is_bookmarked,
            isFetching: false,
          },
        },
      };

    case TOGGLE_BOOKMARKED:
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked: !state.components.bookmark.is_bookmarked,
            isFetching: false,
          },
        },
      };

    case SET_PICKERS_COLLAPSED:
      return {
        ...state,
        variations: {
          ...state.components.variations,
          pickers: state.components.variations.pickers.map(picker => {
            if (picker.id !== action.pickerId) {
              picker.display_info.is_collapsed = !picker.display_info.is_collapsed;
            }
            return picker;
          }),
        },
      };

    case SET_RECOMMENDATIONS_CONTEXT:
      return {
        ...state,
        recommendations_context: action.payload.recommendations_context,
      };

    case FETCH_DEFERRED_START:
      return {
        ...state,
        components: {
          ...state.components,
          html_description: {
            ...state.components.html_description,
            fetched: true,
          },
        },
      };

    case FETCH_DEFERRED_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          html_description: {
            ...action.payload.components.html_description,
            fetched: true,
          },
        },
        error: null,
      };

    case FETCH_DEFERRED_ERROR:
      return {
        ...state,
        html_description: {
          fetched: false,
        },
        error: action.error,
      };

    case ADD_TO_CART_UPDATE_START:
      return {
        ...state,
        components: {
          ...state.components,
          cart_actions: {
            ...state.components.cart_actions,
            card_tooltip: state.components.cart_actions.card_tooltip
              ? {
                  ...state.components.cart_actions.card_tooltip,
                  isFetching: true,
                }
              : null,
          },
        },
      };

    case ADD_TO_CART_UPDATE_COMPLETE:
      return {
        ...state,
        parentUrl: action.payload.parentUrl,
        components: {
          ...state.components,
          ...action.payload.components,
          cart_actions: {
            ...action.payload.components.cart_actions,
            card_tooltip: action.payload.components.cart_actions.card_tooltip
              ? {
                  ...action.payload.components.cart_actions.card_tooltip,
                  isFetching: false,
                }
              : null,
          },
          shipping_summary: {
            ...action.payload.components.shipping_summary,
          },
          second_shipping_summary: action.payload.components.second_shipping_summary
            ? {
                ...action.payload.components.second_shipping_summary,
              }
            : { state: 'HIDDEN' },
          pick_up_summary: {
            ...action.payload.components.pick_up_summary,
          },
          payment_summary: {
            styles: state.components.payment_summary.styles,
            ...action.payload.components.payment_summary,
          },
        },
        feedback: {
          ...action.payload.feedback,
          key: Math.random(),
        },
      };

    case ADD_TO_CART_UPDATE_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          cart_actions: {
            ...state.components.cart_actions,
            card_tooltip: state.components.cart_actions.card_tooltip
              ? {
                  ...state.components.cart_actions.card_tooltip,
                  isFetching: false,
                }
              : null,
          },
        },
        error: action.error,
      };

    case SHOW_QUANTITY_ERROR: {
      let errorMessage;
      if (state.components.available_quantity.picker.error_message) {
        errorMessage = {
          ...state.components.available_quantity.picker.error_message,
          is_deferred: state.components.available_quantity.picker.error_message.is_deferred
            ? null
            : state.components.available_quantity.picker.error_message.is_deferred,
        };
      }
      return {
        ...state,
        components: {
          ...state.components,
          available_quantity: {
            ...state.components.available_quantity,
            picker: {
              ...state.components.available_quantity.picker,
              border_color: errorMessage ? 'red' : null,
              error_message: errorMessage,
            },
          },
        },
      };
    }

    case SHOW_VARIATIONS_ERROR_DESKTOP: {
      const hasPickers = state.components.variations.pickers?.length > 0;
      if (!hasPickers) {
        return state;
      }
      return {
        ...state,
        components: {
          ...state.components,
          variations: {
            ...state.components.variations,
            pickers: state.components.variations.pickers.map(picker => {
              if (!picker.error_message) {
                return picker;
              }
              return {
                ...picker,
                error_message: {
                  ...picker.error_message,
                  is_deferred: picker.error_message.is_deferred ? null : picker.error_message.is_deferred,
                },
              };
            }),
          },
        },
      };
    }

    case SHOW_VARIATIONS_ERROR_MOBILE: {
      const hasPickers = state.components.variations.pickers?.length > 0;
      if (!hasPickers) {
        return state;
      }
      return {
        ...state,
        components: {
          ...state.components,
          [variationsComponent]: {
            ...state.components[variationsComponent],
            pickers: state.components[variationsComponent].pickers.map(picker => {
              if (!picker.error_message) {
                return picker;
              }
              return {
                ...picker,
                error_message: {
                  ...picker.error_message,
                  is_deferred: picker.error_message.is_deferred ? null : picker.error_message.is_deferred,
                },
              };
            }),
            requestKey: Math.random(),
            actionSelected: findActionSelected(state, action.payload.actionKey),
          },
        },
      };
    }

    case SEARCH_QADB_INIT: {
      const searchbar = state.components.qadb.searchBar
        ? state.components.qadb.searchBar
        : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);

      const quickActions =
        state.components.qadb.quickActions ||
        state.components.qadb.qadb_data.components.find(component => component.id === QUICK_ACTIONS);

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            isFetching: true,
            query: action.payload.query,
            searchBar: { ...searchbar, tooltip: null },
            quickActions: { ...quickActions },
          },
        },
      };
    }

    case SEARCH_QADB_SUCCESS: {
      const searchbar = state.components.qadb.searchBar
        ? state.components.qadb.searchBar
        : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            qadb_data: {
              ...action.payload.response,
            },
            searchBar: { ...searchbar, showClose: true },
            smartAnswerModal: {
              isOpen: true,
              isAsking: false,
            },
            isFetching: false,
          },
        },
      };
    }

    case SEARCH_QADB_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            isFetching: false,
          },
        },
      };

    case SEARCH_QADB_ON_BLUR: {
      const searchbar = state.components.qadb.searchBar
        ? state.components.qadb.searchBar
        : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            searchBar: { ...searchbar, focus: false },
          },
        },
      };
    }

    case SEARCH_QADB_ON_FOCUS: {
      const searchbar = state.components.qadb.searchBar
        ? state.components.qadb.searchBar
        : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            searchBar: { ...searchbar, focus: true },
          },
        },
      };
    }

    case SEARCH_QADB_CLOSE: {
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            searchBar: { ...state.components.qadb.searchBar, showClose: false },
            query: '',
            isFetching: true,
            smartAnswerModal: { isOpen: false, isAsking: false },
          },
        },
      };
    }

    case SEARCH_QADB_CLOSE_COMPLETE: {
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            qadb_data: {
              ...action.payload.response,
            },
            searchBar: {
              ...action.payload.response.components.find(component => component.id === SEARCH_BAR),
              focus: true,
              showClose: false,
              key: Math.random(),
            },
            isFetching: false,
          },
        },
      };
    }

    case LEAVE_FEEDBACK_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            qadb_data: {
              components: state.components.qadb.qadb_data.components.map(component => {
                if (component.id === RESULTS) {
                  const newResults = {
                    ...component,
                    components: component.components.map(innerComponent => {
                      if (innerComponent.id === CARD_ANSWER) {
                        const newCardAnswer = {
                          ...innerComponent,
                          smart_answers: innerComponent.smart_answers.map(smart_answer => ({
                            ...smart_answer,
                            showFeedbackMessage: true,
                          })),
                        };
                        return newCardAnswer;
                      }
                      return innerComponent;
                    }),
                  };
                  return newResults;
                }
                return component;
              }),
            },
          },
        },
      };

    case SEARCH_QADB_CALL_TO_ACTION_FOCUS: {
      const searchbar = state.components.qadb.searchBar
        ? state.components.qadb.searchBar
        : state.components.qadb.qadb_data.components.find(component => component.id === SEARCH_BAR);

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            searchBar: { ...searchbar, focus: true, tooltip: action.tooltip },
          },
        },
      };
    }

    case CLOSE_HOW_TO_MAKE_QUESTION_TOOLTIP: {
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            searchBar: {
              ...state.components.qadb.searchBar,
              tooltip: { ...state.components.qadb.searchBar.tooltip, visible: false },
              focus: false,
            },
          },
        },
      };
    }

    case MAKE_QUESTION: {
      const makeQuestion = state.components.qadb.qadb_data.components.find(
        component => component.id === MAKE_QUESTION_SA,
      );
      makeQuestion.initialValue = action.payload.question;

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            makequestion: {
              ...state.components.qadb.makequestion,
              disabled: true,
            },
          },
        },
      };
    }

    case MAKE_QUESTION_SUCCESS: {
      const components = Array.from(state.components.qadb.qadb_data.components);

      const makeQuestion = state.components.qadb.qadb_data.components.find(
        component => component.id === MAKE_QUESTION_SA,
      );
      makeQuestion.initialValue = '';

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            components: [components.filter(component => component.id !== 'RESULTS'), action.payload.results],
            snackMessage: {
              key: Math.random(),
              show: true,
              type: 'success',
              message: action.payload.message,
            },
            query: '',
            smartAnswerModal: { isOpen: false, isAsking: false },
            makequestion: {
              ...state.components.qadb.makequestion,
              disabled: false,
            },
          },
        },
      };
    }

    case SET_SMART_ANSWER: {
      let smart = state.components.qadb.qadb_data.components.find(component => component.id === SMART_ANSWER_MODAL);
      const makeQuestion = state.components.qadb.qadb_data.components.find(
        component => component.id === MAKE_QUESTION_SA,
      );

      makeQuestion.initialValue = '';

      if (smart) {
        smart = action.payload;
      } else {
        state.components.qadb.qadb_data.components.push(action.payload);
      }

      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            smartAnswer: action.payload,
            smartAnswerModal: { isOpen: true, isAsking: false },
            makequestion: {
              ...state.components.qadb.makequestion,
              disabled: false,
            },
          },
        },
      };
    }

    case MAKE_QUESTION_ERROR: {
      const makeQuestion = state.components.qadb.qadb_data.components.find(
        component => component.id === MAKE_QUESTION_SA,
      );
      makeQuestion.initialValue = '';
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            snackMessage: { key: Math.random(), show: true, type: 'error', message: action.payload.message },
            makequestion: {
              ...state.components.qadb.makequestion,
              disabled: false,
            },
          },
        },
      };
    }

    case CLOSE_SMART_ANSWER_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            smartAnswerModal: {
              isOpen: false,
              isAsking: false,
            },
          },
        },
      };

    case OPEN_SMART_ANSWER_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            smartAnswerModal: {
              isOpen: true,
              isAsking: false,
            },
          },
        },
      };

    case OPEN_QUESTION_FROM_SA_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          qadb: {
            ...state.components.qadb,
            smartAnswerModal: {
              isOpen: false,
              isAsking: true,
            },
          },
        },
      };

    case FETCH_ON_CHANGE_TRADE_IN_START:
      return {
        ...state,
        components: {
          ...state.components,
          trade_in: {
            ...state.components.trade_in,
            isFetching: true,
          },
          main_actions: {
            ...state.components.main_actions,
            isFetching: true,
          },
        },
      };
    case FETCH_ON_CHANGE_TRADE_IN_COMPLETE: {
      const { trade_subtitles, background_color, ...updatedTradeIn } =
        action.payload.components.find(component => component.id === 'trade_in') || {};
      return {
        ...state,
        components: {
          ...state.components,
          trade_in: {
            ...state.components.trade_in,
            ...updatedTradeIn,
            trade_subtitles: trade_subtitles || state.components.trade_in?.trade_subtitle,
            background_color,
            isFetching: false,
          },
          main_actions: {
            ...state.components.main_actions,
            ...action.payload.components.find(component => component.id === 'main_actions'),
            isFetching: false,
          },
          coupon_summary: {
            ...state.components.coupon_summary,
            ...action.payload.components.find(component => component.id === 'coupon_summary'),
            isFetching: false,
          },
        },
      };
    }
    case SHOW_SNACKBAR:
      return {
        ...state,
        components: {
          ...state.components,
          snackbar: {
            state: 'VISIBLE',
            message: action.params.message,
            type: action.params.type,
            delay: action.params.delay,
            called_from: action.params.called_from,
            show: true,
            action: action.params.action,
            className: action.params.className,
          },
        },
      };

    case HIDE_SNACKBAR:
      return {
        ...state,
        components: {
          ...state.components,
          snackbar: {
            called_from: state.components?.snackbar?.called_from,
            state: 'HIDDEN',
          },
        },
      };
    case BOTTOMSHEET_MODAL_UPDATE:
      return {
        ...state,
        parentUrl: action.payload.parentUrl,
        selected_offer_type: action.payload.selected_offer_type,
        components: {
          ...state.components,
          ...action.payload.components,
          payment_summary: {
            styles: state.components.payment_summary.styles,
            ...action.payload.components.payment_summary,
          },
        },
        buttonFocus: true,
      };
    case BOTTOMSHEET_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          iframemodal: {
            ...state.components.iframemodal,
            ...action.params,
          },
        },
      };
    case BOTTOMSHEET_MODAL_CLOSE:
      return {
        ...state,
        components: {
          ...state.components,
          iframemodal: {
            ...state.components.iframemodal,
            show: false,
            src: null,
            fallbackConfig: null,
            statsdConfig: null,
            sequencerTrack: null,
          },
        },
        isFetching: false,
      };
    case FETCH_BOTTOMSHEET_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          main_actions: {
            ...state.components.main_actions,
            actions: state.components.main_actions.actions.map(act =>
              act.label.text === action.params.labelText ? { ...act, loading: action.params.loading } : act,
            ),
          },
        },
        isFetching: action.params.isFetching,
      };
    case FETCH_ONE_PAY_FOR_ALL:
      return {
        ...state,
        components: {
          ...state.components,
          main_actions: {
            ...state.components.main_actions,
            actions: action.payload.actions,
          },
        },
        isFetching: action.payload.isFetching,
      };

    case ONE_PAY_FOR_ALL_IFRAME_LOADED:
      return {
        ...state,
        preload: {
          ...state.preload,
          isIframeLoaded: true,
        },
      };

    case ONE_PAY_FOR_ALL_SHOW_MODAL:
      return {
        ...state,
        preload: {
          ...state.preload,
          enabled: action.params.show,
          fallback: action.params.fallback,
        },
      };

    case WISHLIST_SHOW_BOTTOMSHEET_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          wishlist_save_button: {
            ...state.components.wishlist_save_button,
            targetDynamic: action.payload?.targetDynamic || state.components?.wishlist_save_button?.target,
            show: true,
          },
        },
      };

    case WISHLIST_CLOSE_BOTTOMSHEET_MODAL:
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked: action.payload?.isBookmarked || state.components?.bookmark?.is_bookmarked,
          },
          wishlist_save_button: {
            ...state.components.wishlist_save_button,
            targetDynamic: action.payload?.targetDynamic || state.components?.wishlist_save_button?.target,
            show: false,
          },
        },
      };

    case UPDATE_GIFT_REGISTRY: {
      return {
        ...state,
        components: {
          ...state.components,
          bookmark: {
            ...state.components.bookmark,
            is_bookmarked: action.payload?.isBookmarked || state.components?.bookmark?.is_bookmarked,
          },
          wishlist_save_button: {
            ...state.components.wishlist_save_button,
            label: action.payload?.wishlist_save_button?.action?.label?.text,
            gift_registry: {
              ...action.payload?.wishlist_save_button?.gift_registry,
              checkbox: {
                ...action.payload?.wishlist_save_button?.gift_registry?.checkbox,
                check: action.payload?.check || action.payload?.wishlist_save_button?.gift_registry?.checkbox?.check,
              },
            },
          },
        },
      };
    }

    case UPDATE_GIFT_REGISTRY_CHECKBOX: {
      return {
        ...state,
        components: {
          ...state.components,
          wishlist_save_button: {
            ...state.components.wishlist_save_button,
            gift_registry: {
              ...state?.components.wishlist_save_button?.gift_registry,
              checkbox: {
                ...state?.components.wishlist_save_button?.gift_registry?.checkbox,
                check: action.payload?.check,
              },
            },
          },
        },
      };
    }

    case TOGGLE_FOLLOW_OPTIMISTICALLY: {
      const sellerDataComponents = Array.from(state.components.seller_data.components);
      const indexItem = sellerDataComponents.findIndex(el => el.id === 'seller_header');
      const newCtaStatus =
        sellerDataComponents[indexItem].followers.cta_status === 'stop_follow' ? 'do_follow' : 'stop_follow';
      sellerDataComponents[indexItem] = {
        ...sellerDataComponents[indexItem],
        followers: {
          ...sellerDataComponents[indexItem].followers,
          cta_status: newCtaStatus,
          isFetching: true,
        },
      };

      return {
        ...state,
        components: {
          ...state.components,
          seller_data: {
            ...state.components.seller_data,
            components: sellerDataComponents,
          },
        },
      };
    }

    case FETCH_ON_SET_FOLLOW_COMPLETE: {
      const sellerDataComponents = Array.from(state.components.seller_data.components);
      const indexItem = sellerDataComponents.findIndex(el => el.id === 'seller_header');
      let newCtaStatus = sellerDataComponents[indexItem].followers.cta_status;

      if (action.payload && action.payload.status !== 'SUCCESS') {
        newCtaStatus = newCtaStatus === 'stop_follow' ? 'do_follow' : 'stop_follow';
      }

      sellerDataComponents[indexItem] = {
        ...sellerDataComponents[indexItem],
        followers: {
          ...sellerDataComponents[indexItem].followers,
          cta_status: newCtaStatus,
          isFetching: false,
        },
      };

      return {
        ...state,
        components: {
          ...state.components,
          seller_data: {
            ...state.components.seller_data,
            components: sellerDataComponents,
          },
        },
      };
    }

    case FETCH_QUESTION_AI_START:
      return {
        ...state,
        components: {
          ...state.components,
          questions: {
            ...state.components.questions,
            questions_ai: {
              ...state.components.questions.questions_ai,
              isFetching: true,
            },
          },
        },
      };

    case FETCH_QUESTION_AI_COMPLETE:
      return {
        ...state,
        components: {
          ...state.components,
          questions: {
            ...state.components.questions,
            questions_ai: {
              ...state.components.questions.questions_ai,
              ...action.payload,
              isFetching: false,
            },
          },
        },
      };

    case FETCH_QUESTION_AI_ERROR: {
      return {
        ...state,
        components: {
          ...state.components,
          questions: {
            ...state.components.questions,
            questions_ai: {
              ...state.components.questions.questions_ai,
              isFetching: false,
            },
          },
        },
        error: action.error,
      };
    }

    default:
      return state;
  }
};

module.exports = rootReducer;
