const React = require('react');
const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const BottomSheet = require('@andes/bottom-sheet');
const { Modal } = require('@andes/modal');

const { useContext, useEffect, useState, startTransition } = React;
const { bool, string, shape, func } = require('prop-types');

const classNames = require('classnames');
const { VisuallyHidden } = require('@andes/common');
const StaticPropsContext = require('../context/static-props');
const IconFactory = require('../icons/factory-builder')();
const { trackEvent } = require('../../lib/tracking');
const { DEVICE_TYPE } = require('../../utils/constants');

const namespace = 'ui-pdp-bookmark';

const THEME_MODE = { CIRCLE: 'circle' };

const Bookmark = props => {
  const {
    add,
    className,
    csrfToken,
    fetchBookmark,
    isBookmarked,
    itemId,
    onClick,
    productId,
    remove,
    themeMode,
    wishlist,
    closeWishlistModalBS,
    showSnackbar,
    updateGiftRegistry,
    toggleGiftRegistryCheckbox,
  } = props;
  const [isBookmark, setIsBookmark] = useState(isBookmarked);

  const DESKTOP = 'desktop';
  const RESIZE_HEIGHT_METHOD = 'resize-height';
  const ADD_MODAL_HEADER_STYLE_METHOD = 'add-modal-header-style';
  const REMOVE_MODAL_HEADER_STYLE_METHOD = 'remove-modal-header-style';
  const CLOSE_METHOD = 'close';
  const CALLBACK_METHOD = 'callback';
  const CHANGE_TITLE_METHOD = 'change-title';
  const { deviceType } = useContext(StaticPropsContext);
  const [loading, changeLoading] = useState(true);
  const [titleModal, setTitleModal] = useState(wishlist?.title);

  useEffect(() => {
    setIsBookmark(isBookmarked);
  }, [isBookmarked]);

  /* istanbul ignore next */
  const onClose = isSuccess => {
    closeWishlistModalBS(isSuccess);
    changeLoading(true);
    setTitleModal(wishlist?.title);
  };

  /* istanbul ignore next */
  const onCloseWithoutBookmarkSelection = () => {
    closeWishlistModalBS();
    changeLoading(true);
    setTitleModal(wishlist?.title);
  };

  const onInternalClick = e => {
    setIsBookmark(!isBookmark);
    startTransition(() => {
      e.preventDefault();

      if (isBookmark) {
        trackEvent(remove.track);
      } else {
        trackEvent(add.track);
      }
      onClick(e);
    });
  };

  const changeModalHeaderStyle = e => {
    if (deviceType !== 'desktop') {
      return;
    }

    const modalHeader = document.querySelector('.andes-modal__header');
    /* istanbul ignore next */
    if (e.data.method === ADD_MODAL_HEADER_STYLE_METHOD && modalHeader) {
      modalHeader.setAttribute('class', 'andes-modal__header andes-modal__header--shadow');
    }

    /* istanbul ignore next */
    if (e.data.method === REMOVE_MODAL_HEADER_STYLE_METHOD && modalHeader) {
      modalHeader.setAttribute('class', 'andes-modal__header');
    }
  };

  /* istanbul ignore next */
  const handleMessage = e => {
    changeModalHeaderStyle(e);
    if (e.data.method === CHANGE_TITLE_METHOD) {
      setTitleModal(e.data.args.createListTitle);
    }

    const isDesktop = deviceType === DESKTOP;
    if (e.data.type === 'bookmark:refresh' && e.data.id === itemId) {
      fetchBookmark();
    }

    if (e.data.method === CLOSE_METHOD) {
      onClose();
    }

    if (e.data.method === RESIZE_HEIGHT_METHOD) {
      const padding = isDesktop ? 34 : 44;
      const maxHeightPercentBS = 0.66;
      const heightChange =
        e.data.args.height + padding < window.innerHeight * maxHeightPercentBS
          ? e.data.args.height
          : window.innerHeight * maxHeightPercentBS - padding;

      const selector = isDesktop ? '.andes-modal__content' : '.andes-bottom-sheet';
      document.querySelector(selector).style.height = `${heightChange + padding}px`;
    }

    if (e.data.method === CALLBACK_METHOD) {
      const { component, checked, should_update } = e.data.args;
      const COMPONENT_TYPE = 'snackbar';
      const MESSAGE_TYPE_SUCCESS = 'success';
      const CALLED_FROM = 'wishlist_save_button';
      const TAB = 'tab';
      const LIST = 'list';
      if (component?.type === COMPONENT_TYPE) {
        const isChecked = checked || false;

        // comes from create core list
        if (should_update === undefined && !isBookmarked) {
          fetchBookmark();
        }

        // comes from create GR list
        if (should_update === true) {
          if (component?.message_type !== 'error') {
            updateGiftRegistry(isChecked);
          }
          // comes from add to list
        } else if (should_update === false) {
          if (!isBookmarked) {
            fetchBookmark();
          }
          toggleGiftRegistryCheckbox(isChecked);
        }

        const isSuccess = component?.message_type === MESSAGE_TYPE_SUCCESS;
        const hubUrl = new URL(wishlist?.target);
        const goToTab = component?.action?.target.includes(`${TAB}=${LIST}`);
        hubUrl.origin = window.location.origin.toString();
        hubUrl.search = '';
        if (goToTab) {
          const pathname = component?.action?.target.split('?')[0];
          hubUrl.pathname = decodeURIComponent(`${pathname}`);
          hubUrl.searchParams.set(TAB, LIST);
        } else {
          hubUrl.pathname = decodeURIComponent(`${component?.action?.target}`.toString());
        }

        showSnackbar({
          message: component?.message,
          type: component?.message_type,
          className: 'snackbar--add-bookmark',
          delay: component?.delay?.duration || 6000,
          called_from: CALLED_FROM,
          action: {
            text: component.action?.label?.text,
            onClick: () => {
              window.location.assign(hubUrl);
            },
          },
        });
        onClose(isSuccess);
      }
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('message', handleMessage);
    }
    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCircleMode = themeMode === THEME_MODE.CIRCLE;

  const classes = classNames(namespace, `${namespace}__link-bookmark`, className, {
    [`${namespace}__circle-bookmark`]: isCircleMode,
  });
  const iconFilledClassNames = classNames(`${namespace}__icon-bookmark-fill`, {
    [`${namespace}__icon-bookmark-fill--active`]: isBookmark,
  });
  const addLabelBookmarked = add?.label?.text;
  const deleteLabelBookmarked = remove?.label?.text;
  const textBookmark = isBookmark ? deleteLabelBookmarked : addLabelBookmarked;
  const href = `/p/${productId || itemId}/bookmark/${isBookmark ? 'remove' : 'add'}/${itemId}`;

  const renderSmallLabel = () => {
    const showVisuallyHidden = isCircleMode || deviceType === DEVICE_TYPE.DESKTOP || deviceType === DEVICE_TYPE.TABLET;

    return showVisuallyHidden ? <VisuallyHidden>{textBookmark}</VisuallyHidden> : textBookmark;
  };

  return (
    <>
      <form className={classes} method="post" action={href}>
        <input type="hidden" name="_csrf" value={csrfToken} />
        <button
          data-testid="bookmark-anchor"
          className={`${namespace}__link-bookmark`}
          onClick={onInternalClick}
          role="switch"
          type="submit"
          aria-checked={isBookmark}
        >
          {IconFactory({ id: 'bookmark' }, `${namespace}__icon-bookmark`)}
          {IconFactory({ id: 'bookmark' }, iconFilledClassNames)}
          <small data-testid="bookmark-label-hidden" className={classNames(`${namespace}__label`)}>
            {renderSmallLabel()}
          </small>
        </button>
      </form>

      {!!wishlist?.target && deviceType === DESKTOP && (
        <Modal
          srLabel={wishlist?.label}
          open={wishlist?.show}
          onClose={onCloseWithoutBookmarkSelection}
          type="large"
          closeButtonSrLabel={wishlist?.close_modal_label}
          title={!loading ? titleModal : ''}
          className={`${namespace}__modal-wishlist`}
        >
          <div style={{ width: 420, height: !loading ? '100%' : 258 }}>
            {loading && (
              <ProgressIndicatorCircular modifier="fullscreen" size="xlarge" label={wishlist?.loading_text} />
            )}
            <iframe
              id="wishlist-iframe"
              title="wishlist-iframe"
              onLoad={() => changeLoading(false)}
              src={wishlist?.target}
              width="100%"
              height="100%"
              style={{ display: loading ? 'none' : 'block', border: 'none' }}
            />
          </div>
        </Modal>
      )}

      {!!wishlist?.target && deviceType !== DESKTOP && (
        <BottomSheet
          aria-label={wishlist?.label}
          isOpen={wishlist?.show}
          onClose={onCloseWithoutBookmarkSelection}
          initialHeight="auto"
          closable={wishlist?.action?.close_button_position || 'inner'}
        >
          {loading && <ProgressIndicatorCircular modifier="fullscreen" size="medium" />}
          <iframe
            id="wishlist-iframe"
            title="wishlist-iframe"
            onLoad={() => changeLoading(false)}
            src={wishlist?.target}
            width="100%"
            height="100%"
            style={{ display: loading ? 'none' : 'block', border: 'none' }}
          />
        </BottomSheet>
      )}
    </>
  );
};

Bookmark.propTypes = {
  add: shape({
    label: shape({
      text: string.isRequired,
    }),
    icon: shape({
      id: string.isRequired,
    }),
    track: shape({ melidata_event: shape({}), analytics_event: shape({}) }).isRequired,
  }).isRequired,
  csrfToken: string,
  className: string,
  remove: shape({
    label: shape({
      text: string.isRequired,
    }),
    icon: shape({
      id: string.isRequired,
    }),
    track: shape({ melidata_event: shape({}), analytics_event: shape({}) }).isRequired,
  }).isRequired,
  fetchBookmark: func,
  isBookmarked: bool,
  itemId: string,
  onClick: func.isRequired,
  productId: string,
  themeMode: string,
  closeWishlistModalBS: func,
  wishlist: shape({
    label: string,
    show: bool,
    target: string,
    title: string,
    showOnlyForWishlistVariant: bool,
    action: shape({
      close_button_position: string.isRequired,
    }),
  }),
  showSnackbar: func,
  updateGiftRegistry: func,
  toggleGiftRegistryCheckbox: func,
};

Bookmark.defaultProps = {
  className: '',
  isBookmarked: false,
  title: '',
  isFetching: false,
};

module.exports = React.memo(Bookmark);
